import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";
import resources from "./locales/resources";

i18n.use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: "en",
  lng: navigator.language,
  // todo 需要根据环境进行判断
  debug: false,
  resources: resources,
  interpolation: {
    escapeValue: false,
  },
});
export default i18n;
