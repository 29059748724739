export const fr = {
  common: {
    name1: "Page d'accueil",
    name2: "Logiciel",
    name3: "Vidéo",
    name4: "Commentaires",
    name5: "Publier",
    name6: "Messages",
    name7: "Moi",
    name8: "Se déconnecter",
    name9: "Veuillez saisir des mots-clés de recherche",
    name10: "Êtes-vous sûr de vous déconnecter?",
    name11: "Se connecter",
    name12: "Annuler",
    name13: "Confirmer",
    name14: "Site officiel de TECNO",
    name15: "Carte de garantie",
    name16: "Requête de magasin",
    name17: "Scannez le code QR pour télécharger l'application",
    name18: "© TECNO Mobile 2022",
    name19: "La taille de l'image doit être inférieure à 20 MB!",
    name20: "Entrez votre titre",
    name21: "Partagez votre vie",
    name22: "Pas de threads à l'heure actuelle",
    name23: "Veuillez entrer le titre",
    name24: "Veuillez entrer le titre (3 à 90 caractères)",
    name25: "Veuillez saisir le contenu",
    name26: "Veuillez entrer le contenu (3 à 10000 caractères)",
    name27: "Veuillez sélectionner une photo",
    name28: "Publié avec succès",
    name29: "Quelque chose de populaire?",
    name30: "Actualiser",
    name31: "Tout",
    name32: "Publier",
    name33: "Rubrique",
    name34: "Suivre",
    name35: "Suivant",
    name36: "Aucun commentaire pour le moment",
    name37: "Envoyer",
  },
  store: {
    name1: "Sélectionnez vos catégories",
    name2: "Activité",
    name3: "Pour vous",
  },
  video: {
    name1: "Pas de vidéo pour le moment",
  },
  feedback: {
    name1: "TECNO SPOT Officiel",
    name2:
      "Bonjour, bienvenue au centre de service  T-SPOT, veuillez sélectionner",
    name3:
      "Heures de travail: 14:00-20:00 heures de Chine, si vous rencontrez des problèmes en dehors des heures de travail, vous pouvez contacter directement l’administrateur local Veuillez répondre directement au numéro de question dans les 10 minutes",
    name4: "Que puis-je faire pour vous aider?",
    name5: "Enregistrement de l'historique",
  },
  publish: {
    name1: "Publier",
    name2: "Vidéo",
    name3: "La taille de la vidéo doit être inférieure à 100 MB!",
    name4: "La durée de la vidéo ne doit pas dépasser une minute",
    name5: "Voulez-vous annuler les modifications?",
    name6: "Sélectionnez votre catégorie (choix multiple)",
    name7:
      "Téléchargez votre photo (support jpg, png, jepg, pas plus de 20 MB)",
    name8: "Téléchargez votre vidéo (support MP4, pas plus de 1 minute)",
    name9: "Télécharger",
    name10: "Veuillez sélectionner une catégorie",
    name11: "Veuillez sélectionner une vidéo",
  },
  message: {
    name1: "Commentaires",
    name2: "Les j'aime",
    name3: "Messages",
    name4: "Répondre à votre contenu：",
    name5: "Aimer votre contenu",
    name6: "[Photo]",
    name7: "Pas de Likes pour le moment",
    name8: "Aucun message pour le moment",
  },
  my: {
    name1: "Publications",
    name2: "Abonnés",
    name3: "Favoris",
    name4: "Réglage d'enregistrement",
    name5: "Supprimer le message?",
    name6: "Aucune publication pour le moment",
    name7: "Vous ne suivez personne pour le moment",
    name8: "Aucun abonné pour le moment",
    name9: "Aucun favori pour le moment",
    name10: "Aucun sujet pour le moment",
    name11: "Pseudonyme",
    name12: "Téléphone",
    name13: "Adresse e-mail",
    name14: "Nom réel",
    name15: "Ville de résidence",
    name16: "Sexe",
    name17: "Signature",
    name18: "Homme",
    name19: "Femme",
    name20: "Aucun commentaire",
    name21: "Discussion privée",
    name22: "Données personnelles",
    name23: "s'il vous plait, entrez ton surnom",
    name24: "Veuillez entrer votre téléphone",
    name26: "Veuillez saisir votre e-mail",
    name27: "Erreur de format d'e-mail, veuillez remplir à nouveau",
    name28: "Veuillez saisir votre ville de résidence",
    name29: "Veuillez saisir votre signature",
    name30: "Merci de vous présenter.",
  },
  search: {
    name1: "Utilisateur",
    name2: "Enregistrer",
    name3: "Recueilli",
    name4: "afficher le navigateur",
    name5: "Les plus  en vogue",
    name6: "Plus récent",
    name7: "Aucun utilisateur actuellement",
  },
  detail: {
    name1: "Entrez votre réponse",
    name2: "Répondre au message",
    name3: "Répondre",
    name4: "Page précédente",
  },
  private: {
    name1: "Entrez votre message",
    name2: "Pas de messages",
  },
  user: {
    name1: "Envoyer le message",
  },
};
