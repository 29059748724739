/*
 * @Description:
 * @Version: 2.0
 * @Autor: qinhao.chang
 * @Date: 2022-02-28 10:59:21
 * @LastEditors: qinhao.chang
 * @LastEditTime: 2022-09-20 15:16:28
 */
// 关键词高亮
const brightenKeyword = (val, keyword) => {
  const Reg = new RegExp(keyword, "i");
  if (val) {
    if (val.toLocaleUpperCase().indexOf(keyword.toLocaleUpperCase()) > -1) {
      return val.replace(
        Reg,
        `<span style="color: #409EFF;">${
          val.indexOf(keyword.toLocaleUpperCase()) > -1
            ? keyword.toLocaleUpperCase()
            : keyword
        }</span>`
      );
    }
  }
};

// 将光标放在最后一位
const setCursorPosition = (ctrl, pos) => {
  ctrl.focus();
  ctrl.setSelectionRange(pos, pos);
};

// 转换时间
const formatTime = (time) => {
  // 拿到当前的时间戳（毫秒) -- 转换为秒
  const currentTime = new Date();
  const currentTimestamp = parseInt(currentTime.getTime() / 1000);

  // console.log("当前时间----",currentTimestamp)
  // 传进来的时间戳（毫秒)
  const t = new Date(time * 1000);
  const oldTimestamp = parseInt(t.getTime() / 1000);

  // 年
  const oldY = t.getFullYear();
  // 月
  const oldM = t.getMonth() + 1;
  // 日
  const oldD = t.getDate();
  // 时
  const oldH = t.getHours();
  // 分
  const oldi = t.getMinutes();
  // 秒
  const olds = t.getSeconds();

  // 相隔多少秒
  const timestampDiff = currentTimestamp - oldTimestamp;
  // console.log("间距--》", timestampDiff);
  if (timestampDiff < 60 * 5) {
    // 一分钟以内
    return "just now";
  }
  if (timestampDiff < 60 * 60) {
    // 一小时以内
    return Math.floor(timestampDiff / 60) + " Minutes ago";
  }
  // 今天的时间
  if (
    oldY === currentTime.getFullYear() &&
    oldM === currentTime.getMonth() + 1 &&
    oldD === currentTime.getDate()
  ) {
    //   // 10:22
    return `${zeroize(oldH)}:${zeroize(oldi)}:${zeroize(olds)}`;
  }

  // // 剩下的，就是昨天及以前的数据
  return `${oldY}-${zeroize(oldM)}-${zeroize(oldD)}`;

  // 补0
  function zeroize(num) {
    return num < 10 ? "0" + num : num;
  }
};

// localStorage
const localStorageData = {
  //存储
  set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }, //取出数据
  get(key) {
    try {
      const value = localStorage.getItem(key);
      if (value === null || value === undefined || value === "") {
        return null;
      }
      return JSON.parse(localStorage.getItem(key));
    } catch (err) {
      return null;
    }
  }, // 删除数据
  remove(key) {
    localStorage.removeItem(key);
  },

  clear() {
    localStorage.clear();
  }
};

// sessionStorage
const Storage = {
  //存储
  set(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }, //取出数据
  get(key) {
    try {
      const value = sessionStorage.getItem(key);
      if (value === null || value === undefined || value === "") {
        return null;
      }
      return JSON.parse(sessionStorage.getItem(key));
    } catch (err) {
      return null;
    }
  }, // 删除数据
  remove(key) {
    sessionStorage.removeItem(key);
  },

  clear() {
    sessionStorage.clear();
  }
};

// email格式正则
const RegEmail = (str) => {
  const Reg =
    /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
  return Reg.test(str);
};

// 话题跳转
const topicLink = (val, topic) => {
  let str = val;
  topic.map((v, i) => {
    const Reg = new RegExp(`#${v.topicName}`, "gi");
    str = str.replaceAll(
      Reg,
      `<a style="color: #409EFF;cursor:pointer" href='${window.location.origin}/#/topic?topicId=${v.topicId}&topicName=${v.topicName}&isFav=${v.isFav}&heats=${v.heats}'
      >#${v.topicName}</a>`
    );
  });
  return str;
};

// feedback 时间格式化
const timestampToTime = (timestamp) => {
  const date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  const year = date.getFullYear() + "-";
  const month =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  const day =
    date.getDate() < 10 ? "0" + date.getDate() + " " : date.getDate() + " ";
  const hour =
    date.getHours() < 10 ? "0" + date.getHours() + ":" : date.getHours() + ":";
  const minute =
    date.getMinutes() < 10
      ? "0" + date.getMinutes() + ":"
      : date.getMinutes() + ":";
  const second =
    date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  return year + month + day + hour + minute + second;
};

// 识别字符串里的url
const distinguishUrl = (msg) => {
  const reg =
    /((http|https):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|])/g;
  const textR = msg.replace(
    reg,
    "<a href='$1' target='_blank' style='text-decoration: underline;color:#ffffff' rel='noopener noreferrer' >$1</a>"
  );
  return textR;
};

const adjustRatio = (width, height) => {
  const ratio = width / height;
  if (ratio <= 9 / 16) {
    return { width: 360, height: 640, aspectRatio: 9 / 16 };
  } else if (ratio <= 2 / 3) {
    return { width: 240, height: 360, aspectRatio: 2 / 3 };
  } else if (ratio <= 3 / 4) {
    return { width: 270, height: 360, aspectRatio: 3 / 4 };
  } else if (ratio <= 1) {
    return { width: 360, height: 360, aspectRatio: 1 / 1 };
  } else if (ratio <= 4 / 3) {
    return { width: 480, height: 360, aspectRatio: 4 / 3 };
  } else if (ratio <= 3 / 2) {
    return { width: 540, height: 360, aspectRatio: 3 / 2 };
  } else {
    return { width: 640, height: 360, aspectRatio: 16 / 9 };
  }
};

export {
  brightenKeyword,
  // getPositionForTextArea,
  setCursorPosition,
  formatTime,
  Storage,
  RegEmail,
  topicLink,
  localStorageData,
  timestampToTime,
  distinguishUrl,
  adjustRatio
};

// 1、时间是从上往下，按最先顺序排
// 2、5分钟内发布，显示“刚刚”
// 3、1小时内发布，显示具体时间
// 4、1小时以上，显示几小时前发布
// 5、超24小时，显示具体日期
