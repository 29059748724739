/*
 * @Description:
 * @Version: 2.0
 * @Autor: qinhao.chang
 * @Date: 2022-05-10 10:30:28
 * @LastEditors: qinhao.chang
 * @LastEditTime: 2022-05-27 16:26:50
 */
import { en } from "./en";
import { fr } from "./fr";
import { sw } from "./sw";
import { ru } from "./ru"

export default {
  fr: {
    translation: fr,
  },
  en: {
    translation: en,
  },
  sw: {
    translation: sw,
  },
  ru: {
    translation: ru,
  },
};
