/*
 * @Description:
 * @Version: 2.0
 * @Autor: qinhao.chang
 * @Date: 2022-02-14 14:04:00
 * @LastEditors: qinhao.chang
 * @LastEditTime: 2022-07-01 09:35:06
 */

// import "core-js";
// import "regenerator-runtime/runtime";
import React from "react";
import ReactDom from "react-dom";
import "./index.scss";
import store from "./redux/store";
import { Provider } from "react-redux";
import App from "./views/index";
import "antd/dist/antd.css";
import en_GB from "antd/lib/locale/en_GB";
// import fr_FR from "antd/lib/locale/fr_FR";
import { ConfigProvider } from "antd";
import "./i18n";
import "./styles/common.scss";

const Page = (
  <ConfigProvider locale={en_GB}>
    <Provider store={store}>
      <App />
    </Provider>
  </ConfigProvider>
);

ReactDom.render(Page, document.getElementById("root"));
