/*
 * @Description:
 * @Version: 2.0
 * @Autor: qinhao.chang
 * @Date: 2022-03-11 15:34:51
 * @LastEditors: qinhao.chang
 * @LastEditTime: 2022-08-17 14:22:43
 */
// 个人用户信息相关
import Service from "../tools/request";

// PC登录
export function loginForWeb(accessToken) {
  return Service.get("/member/loginForWeb", {
    headers: {
      accessToken,
    },
  });
}
// 获取个人用户信息
export function getMemberInfo(tokens) {
  return Service.post(
    "/member/getMemberInfo",
    {},
    {
      headers: {
        tokens,
      },
    }
  );
}

// 上传修改用户头像
export function uploadAvatarImage(params) {
  return Service.post("/member/uploadAvatarImage", params, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

// 查看其他用户信息
export function getUserInfo(params) {
  return Service.post("/member/getUserInfo", params);
}

// 获取用户粉丝列表
export function getMyFans(params) {
  return Service.post("/member/getMyFans", params);
}

// 获取用户关注列表
export function getMyFollow(params) {
  return Service.post("/member/getMyFollow", params);
}

// 获取用户收藏列表
export function getMyFavorite(params) {
  return Service.post("/forumPlate/getMyFavorite", params);
}

// 获取用户帖子列表
export function getMyThread(params) {
  return Service.post("/forumPlate/getMyThread", params);
}

// 关注用户
export function followMember(params) {
  return Service.post("/member/followMember", params);
}

// 取消关注用户
export function unfollowMember(params) {
  return Service.post("/member/unfollowMember", params);
}

// 获取未读消息数明细
export function getNoticePageTip() {
  return Service.post("/member/getNoticePageTip", {});
}

// 获取帖子评论消息列表
export function getMyPostNotice(params) {
  return Service.post("/member/getMyPostNotice", params);
}

// 获取点赞消息列表
export function getLikesNotice(params) {
  return Service.post("/member/getLikesNotice", params);
}

// 更新评论点赞消息状态
export function checkNotice(params) {
  return Service.post(`/member/checkNotice/${params.id}`, {});
}

// 获取私信消息列表
export function getPrivateNotice(params) {
  return Service.post(`/member/getPrivateNotice`, params);
}

// 获取私信消息记录
export function getHistoryMessage(params) {
  return Service.post(`/member/getHistoryMessage`, params);
}

// 用户搜索
export function getSearchUserResult(params) {
  return Service.post("/member/getSearchUserResult", params);
}

// 更新pc用户信息
export function updateMemberInfoWeb(params) {
  return Service.post("/member/updateMemberInfoWeb", params);
}

// 获取被@消息列表
export function getMentionList(params) {
  return Service.post("/member/getMentionList ", params);
}

// 退出登录
export function logoutTspot(params) {
  return Service.post("/member/Logout ", params);
}