/*
 * @Description:
 * @Version: 2.0
 * @Autor: qinhao.chang
 * @Date: 2022-01-18 14:08:05
 * @LastEditors: qinhao.chang
 * @LastEditTime: 2022-06-30 16:20:51
 */
export const jumpRoute = (params) => ({
  type: "jumpRoute",
  params,
});

export const jumpLogin = (params) => ({
  type: "jumpLogin",
  params,
});

export const loginUserUid = (params) => ({
  type: "loginUserUid",
  params,
});

export const changeCountryCode = (params) => ({
  type: "changeCountryCode",
  params,
});

export const changeCountry = (params) => ({
  type: "changeCountry",
  params,
});

export const getLangList = (params) => ({
  type: "getLangList",
  params,
});

export const getLang = (params) => ({
  type: "getLang",
  params,
});
