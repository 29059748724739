/*
 * @Description:
 * @Version: 2.0
 * @Autor: qinhao.chang
 * @Date: 2022-03-08 13:55:50
 * @LastEditors: qinhao.chang
 * @LastEditTime: 2022-07-29 14:45:19
 */

// 帖子相关
import Service from "../tools/request";

// 首页帖子获取
export function getPlateForyou(params) {
  return Service.post("/forumPlate/getPlateForyou", params);
}

// 其他板块
export function getPlateCommon(params) {
  return Service.post("/forumPlate/getPlateCommon", params);
}

// 帖子详情获取
export function getThreadPostFirst(params) {
  return Service.post("/forumPlate/getThreadPostFirst", params);
}

// 帖子回复列表
export function getThreadPost(params) {
  return Service.post("/forumPlate/getThreadPostNew", params);
}

// 点赞
export function postRates(params) {
  return Service.post("/forumPlate/postRates", params);
}

// 取消点赞
export function cancelPostRates(params) {
  return Service.post("/forumPlate/cancelPostRates", params);
}

// video获取
export function getPlateVideos(params) {
  return Service.post("/forumPlate/getPlateVideos", params);
}

// 帖子详情回复
export function replyThread(params) {
  return Service.post("/forumPlate/replyThread", params);
}

// 帖子详情回复--回复
export function postComment(params) {
  return Service.post("/forumPlate/postComment", params);
}

// 收藏
export function favtimesPlate(params) {
  return Service.post("/forumPlate/favtimesPlate", params);
}

// 搜索帖子列表
export function getSearchResult(params) {
  return Service.post("/forumPlate/search/getSearchResult", params);
}

// 搜索话题
export function getTopics(params) {
  return Service.post("/forumPlate/search/getTopics", params);
}

// 创建话题
export function createTopic(params) {
  return Service.post("/forumPlate/createTopic", params);
}

// 文件上传
export function uploadFileNew(params, config) {
  return Service.post("/forumPlate/uploadFileNew", params, config);
}

// 发帖
export function postNewThread(params) {
  return Service.post("/forumPlate/postNewThread", params);
}

// 板块信息
export function getPostsSection() {
  return Service.post("/forumPlate/getPostsSection", {});
}

// 活动板块
export function getActivityThread(params) {
  return Service.post("/forumPlate/getActivityThread", params);
}

// 获取收藏的话题
export function getFavTopics(params) {
  return Service.post("/forumPlate/getFavTopics", params);
}

// 收藏话题
export function favTopic(params) {
  return Service.post("/forumPlate/favTopic", params);
}

// 热度话题
export function hotTopics(params) {
  return Service.post("/forumPlate/hotTopics", params);
}

// 最新话题
export function newTopics(params) {
  return Service.post("/forumPlate/newTopics", params);
}

// banner
export function getActivityBanner(params) {
  return Service.post("/forumPlate/getActivityBanner", params);
}

// 删帖
export function deleteThread(params) {
  return Service.post("/forumPlate/deleteThread", params);
}

// 获取站点列表
export function getSiteList() {
  return Service.get("/member/getSite", {});
}

// 获取Banner
export function getBanner() {
  return Service.post("/forumPlate/getBanner", {
    bannerGroup: 1,
  });
}


export function getPlateFollow(params) {
  return Service.post("/forumPlate/getPlateFollow",params);
}


export function getRecommendMember() {
  return Service.post("/member/getRecommendMember");
}

// export function getTopics() {
  // return Service.post("forumPlate/category/getTopics");
// }
