/*
 * @Description:
 * @Version: 2.0
 * @Autor: qinhao.chang
 * @Date: 2022-02-27 17:22:39
 * @LastEditors: qinhao.chang
 * @LastEditTime: 2022-09-05 16:23:47
 */
// 数据请求axios二次封装
import axios from "axios";
import qs from "query-string"; //引入qs模块，用来序列化post类型的数据
import { message } from "antd";
import { Storage } from "@/tools/until";

const service = axios.create({
  baseURL: "/api",
  // baseURL: window.baseURL,
  timeout: 300000,
  responseType: "json",
  withCredentials: false, // 是否允许带cookie这些
  headers: {
    "Content-Type": "application/json;charset=utf-8",
  },
});

service.interceptors.request.use(
  (config) => {
    const tokens = Storage.get("tokens") || "";
    const site = Storage.get("countryCode") || "us";
    const language =
      `${Storage.get("lang")}-${Storage.get("countryCode")}` || "";
    config.headers.tokens = tokens;
    config.headers["Accept-Language"] = language;
    config.headers.site = site;
    return config;
  },
  (error) => {
    return error;
  }
);

service.interceptors.response.use(
  (response) => {
    const callbackUrl = `${window.location.origin}/#/following`;
    const code = response.data.code || 0;
    switch (code) {
      case 0:
        // 请求成功
        return response;
      // case 400:
      //   // 请求失败
      //   break;
      case 401:
        // 无效的token信息
        
        Storage.clear();
        if(window.location.hostname.indexOf("ind-spot.shalltry.com") > -1||window.location.hostname.indexOf("ind-tspot.tecno.com") > -1||window.location.hostname.indexOf("in-spot.tecno.com") > -1){
          window.location.href = `https://ind-page.palm.tech/palm-id/#/login?language=en_us&channel=1&brandId=tecnoid&client-id=hiaccount&callbackUrl=${callbackUrl}`
        }
        else{
          window.location.href = `https://page.palm.tech/palm-id/#/login?language=en_us&channel=1&brandId=tecnoid&client-id=hiaccount&callbackUrl=${callbackUrl}`
    
        }
        // window.location.href = `https://page.palm.tech/palm-id/#/login?language=en_us&channel=1&brandId=tecnoid&client-id=hiaccount&callbackUrl=${callbackUrl}`;
        break;
      // case 403:
      //   // 没有权限
      //   break;
      case 404:
        // 访问资源不存在
        window.location.href = `${window.location.origin}/#/following`;
        break;
      case 406:
        // 无效的用户
        window.location.href = `${window.location.origin}/#/following`;
        break;
      // case 411:
      //   // 7天内只能修改一次
      //   message.error({
      //     content: response.data.message,
      //     duration: 2,
      //   });
      //   break;
      case 412:
        // 参数校验失败
        window.location.href = `${window.location.origin}/#/following`;
        break;
      // case 413:
      //   // 用户名已存在
      //   break;
      // case 414:
      //   // 重复关注用户
      //   break;
      // case 500:
      //   // 服务器异常
      //   break;
      case 516:
        // 数据不存在
        message.error({
          content: response.data.message,
          duration: 2,
          onClose: () => {
            window.location.href = `${window.location.origin}/#/following`;
          },
        });
        break;
      case 10001:
        // 无效的token信息
        Storage.clear();
        if(window.location.hostname.indexOf("ind-spot.shalltry.com") > -1||window.location.hostname.indexOf("ind-tspot.tecno.com") > -1||window.location.hostname.indexOf("in-spot.tecno.com") > -1){
          window.location.href = `https://ind-page.palm.tech/palm-id/#/login?language=en_us&channel=1&brandId=tecnoid&client-id=hiaccount&callbackUrl=${callbackUrl}`
        }
        else{
          window.location.href = `https://page.palm.tech/palm-id/#/login?language=en_us&channel=1&brandId=tecnoid&client-id=hiaccount&callbackUrl=${callbackUrl}`
    
        }
        // window.location.href = `https://page.palm.tech/palm-id/#/login?language=en_us&channel=1&brandId=tecnoid&client-id=hiaccount&callbackUrl=${callbackUrl}`;
        break;
      case 10002:
        // 无效的token信息
        Storage.clear();
        if(window.location.hostname.indexOf("ind-spot.shalltry.com") > -1||window.location.hostname.indexOf("ind-tspot.tecno.com") > -1||window.location.hostname.indexOf("in-spot.tecno.com") > -1){
          window.location.href = `https://ind-page.palm.tech/palm-id/#/login?language=en_us&channel=1&brandId=tecnoid&client-id=hiaccount&callbackUrl=${callbackUrl}`
        }
        else{
          window.location.href = `https://page.palm.tech/palm-id/#/login?language=en_us&channel=1&brandId=tecnoid&client-id=hiaccount&callbackUrl=${callbackUrl}`
    
        }
        
        break;
      default:
        message.error({
          content: response.data.message,
          duration: 2,
        });
        break;
    }
  },
  (error) => {
    message.info(error);
  }
);
//最后把封装好的axios导出
export default service;
