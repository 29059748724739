export const en = {
  common: {
    name1: "Homepage",
    name2: "Software",
    name3: "Video",
    name4: "Feedback",
    name5: "Publish",   
    name6: "Messages",
    name7: "Me",
    name8: "Log out",
    name9: "Please enter search keywords",
    name10: "Are you sure to log out?",
    name11: "Log in",
    name12: "Cancel",
    name13: "Confirm",
    name14: "TECNO Official website",
    name15: "Warranty Card",
    name16: "Store Query",
    name17: "Scan the QR code to download the app",
    name18: "© 2022 TECNO Mobile",
    name19: "Image must smaller than 20MB!",
    name20: "Input your title",
    name21: "Share your life",
    name22: "No Threads at present",
    name23: "Please enter the title",
    name24: "Please enter the title（3～90 characters）",
    name25: "Please enter the content",
    name26: "Please enter the content（3～10000 characters）",
    name27: "Please select one photo",
    name28: "Post successfully",
    name29: "Something popular？",
    name30: "Refresh",
    name31: "All",
    name32: "Post",
    name33: "Topic",
    name34: "Follow",
    name35: "Following",
    name36: "No Comments at present",
    name37: "Send",
  },
  store: {
    name1: "Select your categories",
    name2: "Activity",
    name3: "For you",
  },
  video: {
    name1: "No Video at present",
  },
  feedback: {
    name1: "TECNO SPOT Offical",
    name2: "Hello, welcome to the T-SPOT service desk, please select:",
    name3:
      "Manual answering time: 14:00-20:00 China time, if you encounter problems during non-manual shift hours, you candirectly contact the local administrator Please reply directly to the question number within 10 minutes",
    name4: "What can I help you?",
    name5: "History record",
  },
  publish: {
    name1: "Post",
    name2: "Video",
    name3: "The video must smaller than 100MB!",
    name4: "The video duration shall not exceed one minute",
    name5: "Are you sure to quit edit?",
    name6: "Select your category（Multiple choice）",
    name7: "Upload your picture(Support jpg、png、jepg，no more than 20MB)",
    name8: "Upload your Video(Support mp4，no more than 1 minute)",
    name9: "Upload",
    name10: "Please select a category",
    name11: "Please select one video",
  },
  message: {
    name1: "Comments",
    name2: "Likes",
    name3: "Messages",
    name4: "Reply your content：",
    name5: "Like your content",
    name6: "[Picture]",
    name7: "No Likes at present",
    name8: "No Messages at present",
    name9: "@Me",
  },
  my: {
    name1: "Posts",
    name2: "Followers",
    name3: "Favourites",
    name4: "Setting",
    name5: "Delete the post?",
    name6: "No Posts at present",
    name7: "No Following at present",
    name8: "No Followers at present",
    name9: "No Favourites at present",
    name10: "No Topics at present",
    name11: "Nick name",
    name12: "Phone",
    name13: "Email",
    name14: "Actual name",
    name15: "livingCity",
    name16: "Gender",
    name17: "Signature",
    name18: "Male",
    name19: "Female",
    name20: "Rather not to say",
    name21: "Private chat",
    name22: "Personal data",
    name23: "Please enter your nickname",
    name24: "Please enter your phone",
    name26: "Please enter your email",
    name27: "Email format error, please fill in again",
    name28: "Please enter your livingCity",
    name29: "Please enter your signature",
    name30: "Please introduce yourself.",
  },
  search: {
    name1: "User",
    name2: "collect",
    name3: "collected",
    name4: "view browse",
    name5: "Hottest",
    name6: "Newest",
    name7: "No Users at present",
  },
  detail: {
    name1: "Input your reply",
    name2: "Reply the post",
    name3: "Reply",
    name4: "Previous page",
  },
  private: {
    name1: "Input your message",
    name2: "No messages",
  },
  user: {
    name1: "Send Message",
  },
  new:{
    following:"Following",
    foryou:"For you",
    Tech:"Tech",
    Sport:"Sport",
    LifeStyle:"LifeStyle",
    Entertainment:"Entertainment",
    News:"News",
    PostThread:"Post Thread",
    Video:"Video",
    Feedback:"Feedback",
    RecommendedUser:"Recommended User",
    RecommendedTopic:"Recommended Topic",


  }
};
