/*
 * @Description:
 * @Version: 2.0
 * @Autor: qinhao.chang
 * @Date: 2022-01-11 19:39:00
 * @LastEditors: qinhao.chang
 * @LastEditTime: 2022-01-27 16:06:41
 */
import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

const renderRouter = (routes) => {
  return routes ? (
    <Suspense>
      <Routes>
        {routes.map((route, i) => {
          return (
            <Route key={i} path={route.path} element={route.element} exact></Route>
          );
        })}
      </Routes>
    </Suspense>
  ) : null;
};

export { renderRouter };
