export const ru = {
    common: {
      name1: "Домашняя страница",
      name2: "Программное обеспечение",
      name3: "Видео",
      name4: "Обратная связь",
      name5: "Опубликовать",   
      name6: "Сообщения",
      name7: "мне",
      name8: "Выйти из системы",
      name9: "Введите ключевые слова для поиска",
      name10: "Вы уверены, что вышли??",
      name11: "Авторизоваться",
      name12: "отмена",
      name13: "Подтвердить",
      name14: "ТЕenco Официальный сайт",
      name15: "Гарантийный талон",
      name16: "Запрос магазинов",
      name17: "Отсканируйте QR-код, чтобы загрузить приложение",
      name18: "© 2022 TECNO Мобильный",
      name19: "Изображение должно быть меньше 20MB!",
      name20: "Введите название",
      name21: "Поделитесь своей жизнью",
      name22: "В настоящее время нет тем",
      name23: "Введите название",
      name24: "Введите название (3-90)",
      name25: "ведите содержимое",
      name26: "ведите содержимое3～10000",
      name27: "Пожалуйста, выберите одно фото",
      name28: "Опубликовать успешно",
      name29: "Что-то популярное？",
      name30: "Обновить",
      name31: "Все",
      name32: "Опубликовать запись",
      name33: "Тема",
      name34: "Подписаться",
      name35: "Подписки",
      name36: "Нет комментариев в настоящее время",
      name37: "Получить",
    },
    store: {
      name1: "Выберите категории",
      name2: "Активность",
      name3: "На выбор",  
    },
    video: {
      name1: "Нет видео в настоящее время",
    },
    feedback: {
      name1: "T-SPOT Официальный",
      name2: "Здравствуйте, добро пожаловать в службу поддержки T-SPOT, пожалуйста, выберите:",
      name3:"Время ответа вручную: 14:00-20:00 по китайскому времени. Если у вас возникнут проблемы в нерабочее время, вы можете напрямую связаться с местным администратором. Пожалуйста, ответьте прямо на номер вопроса в течение 10 минут.",
      name4: "Чем я могу вам помочь?",
      name5: "Запись истории",
    },
    publish: {
      name1: "почта",
      name2: "Видео",
      name3: "Видео должно быть меньше 100MB!",
      name4: "Продолжительность видео не более одной минуты",
      name5: "Вы уверены, что хотите выйти из редактирования??",
      name6: "Выберите категорию",
      name7: "Загрузите свое изображение (поддерживаются форматы jpg, png, jpeg, не более 20 МБ)",
      name8: "Загрузите свое видео (поддержка mp4, не более 1 минуты)",
      name9: "Загрузить",
      name10: "Выберите категорию",
      name11: "Выберите одно видео",
    },
    message: {
      name1: "Комментарии",
      name2: "Количество лайков",
      name3: "Сообщения",
      name4: "Ответить на ваш контент：",
      name5: "Понравился ваш ответ на",
      name6: "[Изображение]",
      name7: "В настоящее время лайков нет",
      name8: "Нет сообщений в настоящее время",
      name9: "@Мне",
    },
    my: {
      name1: "Опубликовать",
      name2: "Подписчики",
      name3: "Избранное",
      name4: "Параметр",
      name5: "Удалить сообщение?",
      name6: "В настоящее время нет сообщений",
      name7: "В настоящее время нет подписчиков",
      name8: "В настоящее время нет подписчиков",
      name9: "В настоящее время нет Избранного",
      name10: "В настоящее время нет тем",
      name11: "Псевдоним",
      name12: "Телефон",
      name13: "Электронный адрес",
      name14: "Настоящее имя",
      name15: "жизньГород",
      name16: "Пол",
      name17: "Подпись",
      name18: "Мужчина",
      name19: "Женщина",
      name20: "Скорее не сказать",
      name21: "Частный чат",
      name22: "Личные данные",
      name23: "Введите псевдоним",
      name24: "Пожалуйста, введите номер вашего телефона",
      name26: "Пожалуйста, введите адрес электронной почты",
      name27: "Ошибка формата электронной почты, пожалуйста, заполните еще раз",
      name28: "Пожалуйста, введите ваш LivingCity",
      name29: "Пожалуйста, введите свою подпись",
      name30: "Пожалуйста, представьтесь",
    },
    search: {
      name1: "пользователь",
      name2: "Сохранить",
      name3: "Собрано",
      name4: "просмотр",
      name5: "Самые популярные",
      name6: "Новое",
      name7: "Нет пользователей в настоящее время",
    },
    detail: {
      name1: "Введите свой ответ",
      name2: "Ответить на сообщение",
      name3: "Отвечать",
      name4: "Предыдущая страница",
    },
    private: {
      name1: "Введите ваше сообщение",
      name2: "Нет сообщений",
    },
    user: {
      name1: "Отправить сообщение",
    },
    new:{
      following:"следующий",
      foryou:"длятебя",
      Tech:"Технология",
      Sport:"Спорт",
      LifeStyle:"Образжизни",
      Entertainment:"Развлечение",
      News:"Новости",
      PostThread:"Сообщение Тема",
      Video:"видео",
      Feedback:"Обратная связь",
      RecommendedUser:"Рекомендуемый пользователь",
      RecommendedTopic:"Рекомендуемая тема",


    }
  };
  