/*
 * @Description:
 * @Version: 2.0
 * @Autor: qinhao.chang
 * @Date: 2022-01-11 19:38:33
 * @LastEditors: qinhao.chang
 * @LastEditTime: 2022-09-19 15:42:08
 */
import React, { useEffect, useState } from "react";
import { HashRouter as Router, Link, Navigate,useLocation} from "react-router-dom";
import qs from "query-string";
import { renderRouter } from "../renderRouter";
import Loadable from "react-loadable";
import { Layout, Spin, Dropdown, Menu, Modal, Badge } from "antd";
import {
  UserOutlined,
  SearchOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import "./index.scss";
import { Storage, localStorageData } from "@/tools/until";
import { useSelector, useDispatch } from "react-redux";
import {
  jumpLogin,
  jumpRoute,
  changeCountryCode,
  changeCountry,
  getLangList,
  getLang,
} from "../redux/action/routeInfo";
import { getNoticePageTip,logoutTspot} from "@/api/member";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "i18next";
import { getSiteList } from "../api";
import axios from "axios";

function loadingComponent() {
  return (
    <div className="pageLoading">
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </div>
  );
}
// 
const Store = Loadable({
  loader: () => import("./store"),
  loading: loadingComponent,
});
const ForyouIndex = Loadable({
  loader: () => import("./foryou"),
  loading: loadingComponent,
});

// const Tech = Loadable({
//   loader: () => import("./tech"),
//   loading: loadingComponent,
// });

const Sport = Loadable({
  loader: () => import("./sport"),
  loading: loadingComponent,
});

const Lifestyle = Loadable({
  loader: () => import("./lifestyle"),
  loading: loadingComponent,
});

const Entertainment = Loadable({
  loader: () => import("./entertainment"),
  loading: loadingComponent,
});

// const News = Loadable({
//   loader: () => import("./news"),
//   loading: loadingComponent,
// });

const Detail = Loadable({
  loader: () => import("./detail"),
  loading: loadingComponent,
});
const Video = Loadable({
  loader: () => import("./video"),
  loading: loadingComponent,
});

const FeedBack = Loadable({
  loader: () => import("./feedback"),
  loading: loadingComponent,
});
const Publish = Loadable({
  loader: () => import("./publish"),
  loading: loadingComponent,
});
const SearchResult = Loadable({
  loader: () => import("./searchResult"),
  loading: loadingComponent,
});
const Message = Loadable({
  loader: () => import("./message"),
  loading: loadingComponent,
});
const My = Loadable({
  loader: () => import("./my"),
  loading: loadingComponent,
});
const User = Loadable({
  loader: () => import("./user"),
  loading: loadingComponent,
});
const Private = Loadable({
  loader: () => import("./private"),
  loading: loadingComponent,
});
const Topic = Loadable({
  loader: () => import("./topic"),
  loading: loadingComponent,
});
const Share = Loadable({
  loader: () => import("./share"),
  loading: loadingComponent,
});

const routes = [
  {
    path: "/",
    element: <Navigate to="/following" />,
    name: "Following",
    isShow: false,
  },
  {
    defaultName: "foryou",
    path: "/foryou",
    element: <ForyouIndex />,
    name: "Following",
    isShow: true,
  },
  {
    defaultName: "following",
    path: "/following",
    element: <Store />,
    name: "Following",
    isShow: true,
  },

  // {
  //   defaultName: "tech",
  //   path: "/tech",
  //   element: <Tech />,
  //   name: "Tech",
  //   isShow: true,
  // },
  {
    defaultName: "sport",
    path: "/sport",
    element: <Sport />,
    name: "Sport",
    isShow: true,
  },

  {
    defaultName: "lifestyle",
    path: "/lifestyle",
    element: <Lifestyle />,
    name: "lifestyle",
    isShow: true,
  },

  {
    defaultName: "entertainment",
    path: "/entertainment",
    element: <Entertainment />,
    name: "entertainment",
    isShow: true,
  },

  // {
  //   defaultName: "news",
  //   path: "/news",
  //   element: <News />,
  //   name: "news",
  //   isShow: true,
  // },

  // {
  //   path: "/software",
  //   element: <Software />,
  //   name: "Software",
  //   isShow: true,
  // }
  
  {
    path: "/video",
    element: <Video />,
    name: "Video",
    isShow: true,
  },
  {
    path: "/message",
    element: <Message />,
    name: "Message",
    isShow: false,
  },
  {
    path: "/feedback",
    element: <FeedBack />,
    name: "Feedback",
    isShow: true,
  },
  { path: "/publish", element: <Publish />, name: "Publish", isShow: false },
  {
    path: "/searchResult",
    element: <SearchResult />,
    name: "SearchResult",
    isShow: false,
  },
  { path: "/detail", element: <Detail />, name: "Detail", isShow: false },
  {
    path: "/my",
    element: <My />,
    name: "My",
    isShow: false,
  },
  {
    path: "/user",
    element: <User />,
    name: "User",
    isShow: false,
  },
  {
    path: "/private",
    element: <Private />,
    name: "Private",
    isShow: false,
  },
  {
    path: "/topic",
    element: <Topic />,
    name: "Topic",
    isShow: false,
  },
  {
    path: "/share",
    element: <Share />,
    name: "Share",
    isShow: false,
  },
  {
    path: "/*",
    element: <Navigate to="/following" />,
    name: "following",
    isShow: false,
  },
];
const { Header, Content, Footer } = Layout;
const renderContent = renderRouter(routes);

const App = () => {
  const dispatch = useDispatch();
  const [clickSearch, setClickSearch] = useState(false);
  const routeName = useSelector((state) => state.initialName);
  const accessToken = qs.parse(window.location.search).accessToken;
  const isLogin = useSelector((state) => state.isLogin);
  const [unreadNum, setUnReadNum] = useState(null);
  const [selectedKey, setSelectKey] = useState([""]);
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const country = useSelector((state) => state.country);

  // const [lang, setLang] = useState("en");
  const [siteList, setSiteList] = useState([]);
  const langList = useSelector((state) => state.langList);
  const lang = useSelector((state) => state.lang);
  const source = qs.parse(window.location.pathname).source;
  const code = qs.parse(window.location.pathname).countryCode;

  // 切换国家
  const handleChangeCountry = (country, languages, countryCode) => {
    Storage.set("country", country);
    dispatch(changeCountry(country));
    dispatch(getLang(languages[0]));
    Storage.set("lang", languages[0]);
    dispatch(getLangList(languages));
    Storage.set("langList", languages);
    setVisible(!visible);
    changeLanguage(languages[0]);
    Storage.set("countryCode", countryCode);
    dispatch(changeCountryCode(countryCode));
    window.scrollTo(0, 0);
    const { origin } = window.location;
    window.location.href = `${origin}/#/following`;
  };

  // 展示国家遮罩
  const showDrawer = () => {
    setVisible(!visible);
  };

  // 切换语言
  const changeLang = (v) => {
    if (langList.length === 1) return;
    dispatch(getLang(v));
    Storage.set("lang", v);
    changeLanguage(v);
    window.location.reload();
  };

  // refs转发
  const TextInput = React.forwardRef((props, ref) => (
    // 此时inputRef.current=TextInput
    <input
      placeholder={t("common.name9")}
      className="header-search"
      ref={ref}
    />
  ));
  // 创建锚点
  const inputRef = React.createRef();

  // 搜索跳转
  const handleSearch = () => {
    const { origin, pathname } = location;
    location.href = `${origin}${pathname}#/searchResult?query=${inputRef.current.value}`;
  };
  // 隐藏搜索框
  const hideSearchInput = () => {
    inputRef.current.value = "";
    setClickSearch(false);
    // console.log(window.location);
  };

  // 切换Tab路由
  const changeRoute = (el) => {
    dispatch(jumpRoute(el.path));
    const { origin, pathname } = location;
    location.href = `${origin}${pathname}#${el.path}`;
  };

  // 跳转登录
  const jumpToLogin = () => {
    const callbackUrl = `${window.location.origin}/#/following`;
    if(window.location.hostname.indexOf("ind-spot.shalltry.com") > -1||window.location.hostname.indexOf("ind-tspot.tecno.com") > -1||window.location.hostname.indexOf("in-spot.tecno.com") > -1){
      window.location.href = `https://ind-page.palm.tech/palm-id/#/login?language=en_us&channel=1&brandId=tecnoid&client-id=hiaccount&callbackUrl=${callbackUrl}`
    }
    else{
      window.location.href = `https://page.palm.tech/palm-id/#/login?language=en_us&channel=1&brandId=tecnoid&client-id=hiaccount&callbackUrl=${callbackUrl}`

    }
    
    
    
  };
  // 退出登录
  const Logout = () => {
    Modal.confirm({
      content: t("common.name10"),
      cancelText: t("common.name12"),
      okText: t("common.name13"),
      wrapClassName: "logout",
      onOk: () => {
        if (Storage.get("source")) {
          Storage.clear();
          
        } else {
           //退出登录，在我们自己这边退出
           logoutTspot().then((res) => {
           }).catch(() => {
            });
          const { hostname } = window.location;
          const url =
            hostname.indexOf("ind-spot.shalltry.com") > -1||hostname.indexOf("ind-tspot.tecno.com") > -1||hostname.indexOf("in-spot.tecno.com") > -1
              ? "https://ind-account.palm.tech/sdk/login/logout"
              : "https://account.palm.tech/sdk/login/logout";
          axios({
            methods: "get",
            url: url,
            headers: {
              Authorization: `Bearer ${Storage.get("accessToken")}`,
              "Client-ID": "hiaccount",
            },
          })
            .then((res) => {})
            .catch(() => {});
           
        }

        setTimeout(() => {
          window.location.href = `${window.location.origin}`;
          Storage.clear();
        }, 2000);
      },
    });
  };
  // logo 跳转首页
  const jumpToHome = () => {
    dispatch(jumpRoute("/following"));
    window.location.href = `${window.location.origin}/#/following`;
  };
  // 获取未读消息
  async function getOwnNoticePageTip() {
    const res = await getNoticePageTip();
    const tempObj = JSON.parse(JSON.stringify(res.data.data));
    const tempNum = tempObj.post + tempObj.rate + tempObj.pm;
    setUnReadNum(tempNum);
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    const login = Storage.get("tokens") ? true : false;
    dispatch(jumpLogin(login));
    if (!localStorageData.get("mark")) {
      const onlyMark = "pc-" + uuidv4() + new Date().getTime();
      localStorageData.set("mark", onlyMark);
    }
    if (login) {
      getOwnNoticePageTip();
      // localStorageData.set("mark", "");
    }
    const key = ["/message", "/my"].includes(routeName)
      ? routeName == "/message"
        ? ["0"]
        : ["1"]
      : [""];
    setSelectKey(key);
    return () => {
      setClickSearch(false);
    };
  }, [routeName]);

  useEffect(() => {
    // const pathnameroute = useLocation().pathname;
    // console.log(useLocation().pathname,'pathnameroute')
    const type = navigator.userAgent;
    console.log(window.location.hostname,window.location.href,'window.location.hostname')
    const routerLink=window.location.href.split('#')[1]
    let requeryNUm=''
    console.log(type,routerLink,'routerLink')
    if(window.location.hostname.indexOf("in-spot.tecno.com") > -1){
      //in的域名跳转到ind
      window.location.href = `https://ind-tspot.tecno.com/`
    }
    if (type.indexOf("Android") > 0 || type.indexOf("iPhone") > 0) {
      if(window.location.hostname.indexOf("tspot.tecno.com") > -1||window.location.hostname=="spot.shalltry.com"){
        if(routerLink.indexOf("/share")>-1||routerLink.indexOf("/detail")>-1){
          requeryNUm=routerLink.split('?')[1].split('=')[1]
            window.location.href = `https://wap.tspot.tecno.com/webApp/list/share?id=${requeryNUm}`;
        }
        else if(routerLink.indexOf("/topic")>-1){
          window.location.href = `https://wap.tspot.tecno.com/webApp${routerLink}`;
        }
        else{
          window.location.href = `https://wap.tspot.tecno.com/webApp`;

        }
        
      }
      else if (window.location.hostname.indexOf("test-spot.tecno.com") > -1||window.location.hostname=="test-spot.tecno-dev.net") {
        if(routerLink.indexOf("/share")>-1||routerLink.indexOf("/detail")>-1){
          requeryNUm=routerLink.split('?')[1].split('=')[1]
          console.log(requeryNUm,'requeryNUm')
            window.location.href = `https://test-wap-tspot.tecno.com/webApp/list/share?id=${requeryNUm}`;
        }
        else if(routerLink.indexOf("/topic")>-1){
          window.location.href = `https://test-wap-tspot.tecno.com/webApp${routerLink}`;
        }
        else{
          window.location.href = `https://test-wap-tspot.tecno.com/webApp`;

        }
        
      } else if (window.location.hostname.indexOf("uat-spot.tecno-dev") > -1) {
        if(routerLink.indexOf("/share")>-1||routerLink.indexOf("/detail")>-1){
          requeryNUm=routerLink.split('?')[1].split('=')[1]
            window.location.href = `https://uat-wap-tspot.tecno-dev.net/webApp/list/share?id=${requeryNUm}`;
        }
        else if(routerLink.indexOf("/topic")>-1){
          window.location.href = `https://uat-wap-tspot.tecno-dev.net/webApp${routerLink}`;
        }
        else{
          window.location.href = `https://uat-wap-tspot.tecno-dev.net/webApp`;

        }
       
      }  else if (window.location.hostname.indexOf("ind-spot.shalltry.com") > -1||window.location.hostname.indexOf("ind-tspot.tecno.com") > -1||window.location.hostname.indexOf("in-spot.tecno.com") > -1) {
        if(routerLink.indexOf("/share")>-1||routerLink.indexOf("/detail")>-1){
            requeryNUm=routerLink.split('?')[1].split('=')[1]
            console.log(requeryNUm,'requeryNUm')
            window.location.href = `https://ind-wap.tspot.tecno.com/webApp/list/share?id=${requeryNUm}`;
        }
        else if(routerLink.indexOf("/topic")>-1){
          window.location.href = `https://ind-wap.tspot.tecno.com/webApp${routerLink}`;
        }
        else{
          window.location.href = `https://ind-wap.tspot.tecno.com/webApp`;
          
        }  
      }
    }
    getSiteList().then((res) => {
      const { origin, hostname } = window.location;
      if (hostname.indexOf("ind-spot.shalltry.com") > -1||hostname.indexOf("ind-tspot.tecno.com") > -1||hostname.indexOf("in-spot.tecno.com") > -1) {
        const tempList = res.data.data.filter((v) => v.country === "India");
        setSiteList(tempList);
        Storage.set("country", "India");
        dispatch(changeCountry("India"));
        Storage.set("lang", "en");
        dispatch(getLangList(["en"]));
        Storage.set("langList", ["en"]);
        changeLanguage("en");
        Storage.set("countryCode", tempList[0].countryCode);
      } else {
        setSiteList(res.data.data);
      }
    });
    if (lang) {
      changeLanguage(lang);
    }
  }, []);

  return (
    <Router>
      <Layout className="layout">
        <Header
          style={{
            position: "fixed",
            width: "100%",
          }}
        >
          <div className="header">
            <div className="header-left">
              <div
                className="logo"
                style={{ cursor: "pointer" }}
                onClick={jumpToHome}
              >
                <Link to="/following">
                  <img
                    src={require("../assets/images/LOGO.svg")}
                    alt=""
                    width={120}
                    height={48}
                  ></img>
                </Link>
              </div>
              <ul className="nav-menu">
                {[
                  {
                    defaultName: "/following",
                    path: "/following",
                    element: <Store />,
                    name: t("new.following"),
                  },
                  {
                    defaultName: "/foryou",
                    path: "/foryou",
                    element: <ForyouIndex />,
                    name: t("new.foryou"),
                  },
                  // {
                  //   defaultName: "/tech",
                  //   path: "/tech",
                  //   element: <Tech />,
                  //   name: t("new.Tech"),
                  // },

                  {
                    defaultName: "/sport",
                    path: "/sport",
                    element: <Sport />,
                    name: t("new.Sport"),
                  },

                  {
                    defaultName: "/lifestyle",
                    path: "/lifestyle",
                    element: <Lifestyle />,
                    name: t("new.LifeStyle"),
                  },

                  {
                    defaultName: "/entertainment",
                    path: "/entertainment",
                    element: <Entertainment />,
                    name: t("new.Entertainment"),
                  },

                  // {
                  //   defaultName: "/news",
                  //   path: "/news",
                  //   element: <News />,
                  //   name: t("new.News"),
                  // },
                  // {
                  //   defaultName: "/video",
                  //   path: "/video",
                  //   element: <Video />,
                  //   name: t("common.name3"),
                  // },
                  // {
                  //   path: "/feedback",
                  //   element: <FeedBack />,
                  //   name: t("common.name4"),
                  // },
                ].map((item, index) => {
                  return (
                    <li
                      className={`common ${
                        routeName === item.defaultName ? "selected" : ""
                      }`}
                      key={index}
                      onClick={() => changeRoute(item)}
                    >
                      {item.name}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div
              className="header-right"
              style={{ display: !clickSearch ? "flex" : "none" }}
            >
              <div className="choose-station">
                <div className="station-lang">
                  {langList.length > 1 &&
                    langList.map((v, i) => {
                      return (
                        <div key={i} className="lang-name">
                          <span
                            className={`default-lang ${
                              v === lang ? "select-lang" : ""
                            }`}
                            onClick={() => changeLang(v)}
                          >
                            {v}
                          </span>
                          {langList.length > 1 && i == 0 ? <span>/</span> : ""}
                        </div>
                      );
                    })}
                </div>
                <div
                  className="choose-country"
                  onClick={siteList.length > 1 ? showDrawer : null}
                >
                  <span className="country-name">
                    {country}
                    <span className="country-name">
                      {
                        // source?(code):(country)
                        code
                      }
                    </span>
                  </span>
                  {siteList.length > 1 ? (
                    visible ? (
                      <UpOutlined
                        style={{ fontSize: "15px", color: "#ffffff" }}
                      />
                    ) : (
                      <DownOutlined
                        style={{ fontSize: "15px", color: "#ffffff" }}
                      />
                    )
                  ) : null}
                </div>
              </div>
              {isLogin || accessToken ? (
                <>
                  <div className="publish">
                    <Link to="/publish">
                      <span>{t("common.name5")}</span>
                    </Link>
                  </div>
                  <div className="mine">
                    <Dropdown
                      overlayClassName="header-dropDown"
                      overlay={
                        <Menu
                          defaultSelectedKeys={selectedKey}
                          selectedKeys={selectedKey}
                        >
                          <Menu.Item key="0">
                            <Badge
                              dot={unreadNum ? true : false}
                              className="message-badge"
                            >
                              <Link to="/message">{t("common.name6")}</Link>
                            </Badge>
                          </Menu.Item>
                          <Menu.Item key="1">
                            <Link to="/my">{t("common.name7")}</Link>
                          </Menu.Item>
                          <Menu.Item key="2">
                            <span onClick={Logout}>{t("common.name8")}</span>
                          </Menu.Item>
                        </Menu>
                      }
                      trigger={["hover"]}
                      arrow
                    >
                      <a
                        className="ant-dropdown-link"
                        onClick={(e) => e.preventDefault()}
                      >
                        <UserOutlined style={{ color: "#000" }} />
                      </a>
                    </Dropdown>
                  </div>
                </>
              ) : (
                <div className="login" onClick={jumpToLogin}>
                  {t("common.name11")}
                </div>
              )}
              <div className="search" onClick={() => setClickSearch(true)}>
                <SearchOutlined style={{ color: "#000" }} />
              </div>
            </div>
            <div
              className="header-right"
              style={{ display: !clickSearch ? "none" : "flex" }}
            >
              <TextInput ref={inputRef} />
              <div className="search-icon" onClick={handleSearch}>
                <SearchOutlined
                  style={{ fontSize: "16px", color: "#070203" }}
                />
              </div>
              <CloseCircleOutlined
                style={{ color: "#FFFFFF", fontSize: "25px", zIndex: 10 }}
                onClick={hideSearchInput}
              />
            </div>
          </div>
        </Header>
        <div
          className={`country-drawer ${
            visible ? "show-drawer" : "hidden-drawer"
          }`}
        >
          <div
            className="country-content-select"
            style={{ display: visible ? "flex" : "none" }}
          >
            {siteList.map((v, i) => {
              return (
                <div
                  key={v.id}
                  className="country-content-flag"
                  onClick={() =>
                    handleChangeCountry(v.country, v.languages, v.countryCode)
                  }
                >
                  <img src={v.banner} className="nation-flag" />
                  <span
                    className={`nation-name ${
                      v.country === country ? "selected" : ""
                    }`}
                  >
                    {v.country}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        <Content>{renderContent}</Content>
        <Footer>
          <div className="footer">
            <div className="footer-top">
              <div className="external-links">
                <a href="https://www.tecno-mobile.com/home/#/">
                  {t("common.name14")}
                </a>
                <a href="https://www.tecno-mobile.com/warranty/#/">
                  {t("common.name15")}
                </a>
                <a href="https://www.tecno-mobile.com/stores/?isApp=1#/">
                  {t("common.name16")}
                </a>
              </div>
              <div className="download-code">
                <div className="scan-text">{t("common.name17")}</div>
                <div className="scan-code">
                  <div className="scan-qrcode"></div>
                </div>
              </div>
            </div>
            <div className="footer-bottom">{t("common.name18")}</div>
          </div>
        </Footer>
      </Layout>
    </Router>
  );
};

export default App;
