/*
 * @Description:
 * @Version: 2.0
 * @Autor: qinhao.chang
 * @Date: 2022-01-18 11:24:03
 * @LastEditors: qinhao.chang
 * @LastEditTime: 2022-06-30 16:20:18
 */
import { Storage } from "../../tools/until";

const init = {
  initialName: "/following",
  isLogin: false,
  uid: Storage.get("uid") || null,
  countryCode: Storage.get("countryCode") || "",
  country: Storage.get("country") || "Global",
  langList: Storage.get("langList") || ["en"],
  lang: Storage.get("lang") || "en",
};

export default (state = init, action) => {
  const { type, params } = action;
  switch (type) {
    case "jumpRoute":
      return { ...state, initialName: params };
    case "jumpLogin":
      return { ...state, isLogin: params };
    case "loginUserUid":
      return { ...state, uid: params };
    case "changeCountryCode":
      return { ...state, countryCode: params };
    case "changeCountry":
      return { ...state, country: params };
    case "getLangList":
      return { ...state, langList: params };
    case "getLang":
      return { ...state, lang: params };
    default:
      return state;
  }
};
