export const sw = {
  common: {
    name1: "Ukurasa wa nyumbani",
    name2: "Programu",
    name3: "Video",
    name4: "Maoni",
    name5: "Chapisha",
    name6: "Jumbe",
    name7: "Mimi",
    name8: "Toka",
    name9: "Tafadhali weka manenomsingi ya utafutaji",
    name10: "Je, unahakika ya kutoka ?",
    name11: "Ingia",
    name12: "Ghairi",
    name13: "Dhibitisha",
    name14: "Tovuti Rasmi ya TECNO",
    name15: "Kadi ya dhamana",
    name16: "Duka la Swali",
    name17: "Changanua msimbo wa QR ili kupakua programu",
    name18: "© 2022 TECNO Mobile",
    name19: "Picha lazima iwe ndogo kuliko MB 20!",
    name20: "Ingiza kichwa chako",
    name21: "Gawiza maisha yako",
    name22: "Hakuna nyuzi kwa sasa",
    name23: "Tafadhali ingiza cheo",
    name24: "Tafadhali weka kichwa (vibambo 3~90)",
    name25: "Tafadhali ingiza maudhui",
    name26: "Tafadhali weka maudhui (vibambo 3~10000)",
    name27: "Tafadhali teua picha moja",
    name28: "Faulu kuchapisha",
    name29: "Kitu maarufu?",
    name30: "Onyesha upya",
    name31: "Zote",
    name32: "Chapisha",
    name33: "Mada",
    name34: "Fuata",
    name35: "Unaofuata",
    name36: "Hakuna Maoni kwa sasa",
    name37: "Tuma",
  },
  store: {
    name1: "Teua kategoria zako",
    name2: "Shughuli",
    name3:"Kwa ajili yako"
  },
  video: {
    name1: "Hakuna Video kwa sasa",
  },
  feedback: {
    name1: "TECNO SPOT Rasmi",
    name2:
      "Hujambo, karibu kwenye dawati la huduma la T-SPOT, tafadhali chagua:",
    name3:
      "Muda wa kujibu mwenyewe: 14:00-20:00 Saa za Uchina, ukikumbana na matatizo wakati wa zamu isiyo ya kawaida, unaweza kuwasiliana moja kwa moja na msimamizi wa eneo lako Tafadhali jibu moja kwa moja nambari ya swali ndani ya dakika 10.",
    name4: "Nikusaidie nini?",
    name5: "Rekodi ya historia",
  },
  publish: {
    name1: "Chapisha",
    name2: "Video",
    name3: "Video lazima iwe ndogo kuliko MB 100!",
    name4: "Muda wa video hautazidi dakika moja",
    name5: "Je, una uhakika wa kuacha kuhariri?",
    name6: "Teua kategoria yako (chaguo nyingi)",
    name7: "Pakia picha yako (inatumia jpg, png, jepg, isiyozidi MB 20)",
    name8: "Pakia video yako (inatumia MP4, si zaidi ya dakika 1)",
    name9: "Pakia",
    name10: "Tafadhali chagua aina",
    name11: "Tafadhali chagua video moja",
  },
  message: {
    name1: "Maoni",
    name2: "Kupenda",
    name3: "Jumbe",
    name4: "Jibu maudhui yako：",
    name5: "Penda maudhui yako",
    name6: "[Picha]",
    name7: "Hakuna Imenipendeza kwa sasa",
    name8: "Hakuna Jumbe kwa sasa",
  },
  my: {
    name1: "Chapisho",
    name2: "Wanaokufuata",
    name3: "Vipendwa",
    name4: "Mpangilio",
    name5: "Ungependa kufuta chapisho hilo?",
    name6: "Hakuna Machapisho kwa sasa",
    name7: "Hakuna Wafuasi kwa sasa",
    name8: "Hakuna Wafuasi kwa sasa",
    name9: "Hakuna Vipendwa kwa sasa",
    name10: "Hakuna Mada kwa sasa",
    name11: "Jina la utani",
    name12: "Simu",
    name13: "Barua pepe",
    name14: "Jina halisi",
    name15: "Mji wa Kuishi",
    name16: "Jinsia",
    name17: "Sahihi",
    name18: "Mwanaume",
    name19: "Mwanamke",
    name20: "Heri nisiseme",
    name21: "Gumzo la faragha",
    name22: "Data binafsi",
    name23: "Tafadhali weka jina lako la utani",
    name24: "Tafadhali weka simu yako",
    name26: "Tafadhali weka barua pepe yako",
    name27: "Hitilafu ya umbizo la barua pepe, tafadhali jaza tena",
    name28: "Tafadhali ingiza Mji wako wa kuishi",
    name29: "Tafadhali weka sahihi yako",
    name30: "Tafadhali jitambulishe.",
  },
  search: {
    name1: "Mtumiaji",
    name2: "Funga",
    name3: "Imekusanywa",
    name4: "Tazama kuvinjari",
    name5: "Maarufu",
    name6: "Mpya zaidi",
    name7: "Hakuna Watumiaji kwa sasa",
  },
  detail: {
    name1: "Ingiza jibu lako",
    name2: "Jibu chapisho",
    name3: "Jibu",
    name4: "Ukurasa uliotangulia",
  },
  private: {
    name1: "Ingiza ujumbe wako",
    name2: "Hakuna ujumbe",
  },
  user: {
    name1: "Tuma Ujumbe",
  },
};
